<template>
  <b-card header-tag="header" class="card-custom">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("EMPLOYEE.HEADER") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="primary"
          class="font-weight-bolder text-white"
          v-b-toggle.employee-list-sidebar
        >
          {{ $t("EMPLOYEE.BUTTON") }}
        </b-button>
      </div>

      <b-sidebar
        ref="employee-list-sidebar-ref"
        id="employee-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('EMPLOYEEFORM.CREATE')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <EmployeeForm
            v-slot:default="{}"
            :title="$t('EMPLOYEEFORM.EMPLOYEEDETAILS')"
            v-on:success="closeSideBar"
          ></EmployeeForm>
        </div>
      </b-sidebar>
    </div>
    <b-tabs pills class="mt-5" nav-class="nav-light-primary">
      <b-tab lazy :title="$t('ECOMMERCE.COMMON.ALL')">
        <EmployeeTable :reload="reload"></EmployeeTable>
      </b-tab>
      <b-tab lazy :title="$t('BUTTON.UNVERIFIED')">
        <EmployeeUnverifiedTable :reload="reload"></EmployeeUnverifiedTable>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmployeeForm from "@/modules/company/components/employees/EmployeePageCreateForm";
import EmployeeTable from "@/modules/company/components/employees/EmployeePageTable";
import EmployeeUnverifiedTable from "@/modules/company/components/employees/EmployeeUnverifiedTable";

export default {
  name: "EmployeeList",
  components: { EmployeeUnverifiedTable, EmployeeTable, EmployeeForm },
  data() {
    return {
      reload: false,
    };
  },
  mounted() {
    console.log("Employee List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/company/employee/list" },
    ]);
  },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["employee-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
