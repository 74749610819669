<template>
  <EmployeeGeneralDetailForm></EmployeeGeneralDetailForm>
</template>

<script>
import EmployeeGeneralDetailForm from "@/modules/company/components/employees/details/EmployeeGeneralDetailForm";
export default {
  name: "EmployeeGeneralDetail",
  components: { EmployeeGeneralDetailForm },
};
</script>

<style scoped></style>
