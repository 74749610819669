<template>
  <div>
    <b-table
      id="employee-page-table"
      ref="employee-page-table"
      responsive="sm"
      :fields="fields"
      :items="getEmployees"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(full_name)="data">
        <div class="d-flex flex-row align-content-center mt-3">
          <div
            v-if="!data.item.image_url"
            class="symbol symbol-50 symbol-circle symbol-light-primary mr-10"
          >
            <span class="symbol-label font-size-h4">{{
              data.item.first_name !== "" ? data.item.first_name.charAt(0) : "A"
            }}</span>
          </div>
          <div class="mr-10" v-if="data.item.image_url">
            <b-avatar :src="data.item.image_url" size="4em"></b-avatar>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span class="font-weight-bolder">
              {{ data.item.first_name }}
            </span>
            <span class="font-weight-bold">
              {{ data.item.unique_code }}
            </span>
            <span class="font-weight-bold">
              {{ data.item.email }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEmployeeDetails(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="assignAsAdmin(data)"
            v-if="getSuperAdminPermission"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeDetails(data)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="employees.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="refresh"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>

    <EmployeePageDetailModal></EmployeePageDetailModal>
    <CompanyAdminAssignmentModal
      :employee="selectedEmployee"
    ></CompanyAdminAssignmentModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { alert } from "sweetalert2";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import EmployeePageDetailModal from "@/modules/company/components/employees/details/EmployeePageDetailModal";
import CompanyAdminAssignmentModal from "@/modules/company/components/employees/admin/CompanyAdminAssignmentModal";

export default {
  name: "EmployeeTable",
  props: ["reload"],
  components: {
    CompanyAdminAssignmentModal,
    EmployeePageDetailModal,
    AppLoader,
  },
  mounted() {
    this.$store.dispatch("emptyEmployeeList");
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        // A virtual column made up from two fields
        { key: "full_name", label: this.$t("EMPLOYEE.HEADER") },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
      selectedEmployee: {},
    };
  },
  methods: {
    getEmployees() {
      return this.$store
        .dispatch("getEmployeeList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    showEmployeeDetails(row) {
      console.log("Selected employee ", row);
      this.$bvModal.show("employee-page-detail-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },

    deleteEmployeeDetails(row) {
      console.log(row.item);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.item.first_name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteEmployee", row.item.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    refresh() {
      console.log("Reload Table!");
      this.$refs["employee-page-table"].refresh();
    },

    assignAsAdmin(data) {
      console.log("Assigning ", data.item.id);
      this.selectedEmployee = {
        name: data.item.first_name,
        id: data.item.id,
      };
      this.$bvModal.show("employee-admin-assignment-modal");
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["employee-page-table"].refresh();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.CompanyModule.employee.list.employees,
      meta: (state) => state.CompanyModule.employee.list.meta,
    }),
    ...mapGetters(["getSuperAdminPermission"]),
  },
};
</script>

<style scoped></style>
