<template>
  <b-modal
    id="employee-page-detail-modal"
    :title="$t('EMPLOYEEFORM.EMPLOYEEDETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="xl"
    hide-footer
  >
    <b-tabs
      pills
      fill
      justified
      v-model="currentTab"
      nav-class="nav-light-primary"
    >
      <!-- begin:: Employee General Details tab -->
      <b-tab :title="$t('COMPANY.REGISTRATION.GENERAL')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeGeneralDetail></EmployeeGeneralDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('EMPLOYEEFORM.PERSONAL')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeePersonalDetail></EmployeePersonalDetail>
        </div>
      </b-tab>
      <!-- end:: Employee General Details tab -->
      <b-tab :title="$t('COMPANY.REGISTRATION.CONTACT')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeContactsDetail></EmployeeContactsDetail>
        </div>
      </b-tab>
      <!--<b-tab :title="$t('JOB.JOB')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeJobDetail></EmployeeJobDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('SALARY.SALARY')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeSalaryDetail></EmployeeSalaryDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('FAMILY.FAMILY')" lazy>
        <div class="d-flex px-3 py-5 flex-column">
          <EmployeeFamilyDetail></EmployeeFamilyDetail>
        </div>
      </b-tab>
      <b-tab :title="$t('EDUCATION.EDUCATION')" lazy>
        <div class="d-flex px-3 py-5 mt-5 flex-column">
          <EmployeeEducationDetail></EmployeeEducationDetail>
        </div>
      </b-tab>-->
      <!--      <b-tab :title="$t('SUMMARY.SUMMARY')" lazy>-->
      <!--        <div class="d-flex px-3 py-5 mt-5 flex-column">-->
      <!--          <EmployeeSummaryDetail></EmployeeSummaryDetail>-->
      <!--        </div>-->
      <!--      </b-tab>-->
    </b-tabs>
  </b-modal>
</template>

<script>
import EmployeeGeneralDetail from "@/modules/company/components/employees/details/EmployeeGeneralDetail";
import EmployeePersonalDetail from "@/modules/company/components/employees/details/EmployeePersonalDetail";
import EmployeeContactsDetail from "@/modules/company/components/employees/details/EmployeeContactsDetail";
//import EmployeeJobDetail from "@/modules/company/components/employees/details/EmployeeJobDetail";
//import EmployeeSalaryDetail from "@/modules/company/components/employees/details/EmployeeSalaryDetail";
//import EmployeeFamilyDetail from "@/modules/company/components/employees/details/EmployeeFamilyDetail";
//import EmployeeEducationDetail from "@/modules/company/components/employees/details/EmployeeEducationDetail";
export default {
  name: "EmployeePageDetailModal",
  components: {
    // EmployeeJobDetail,
    EmployeeGeneralDetail,
    EmployeePersonalDetail,
    EmployeeContactsDetail,
    //EmployeeJobDetail,
    //EmployeeSalaryDetail,
    //EmployeeFamilyDetail,
    //EmployeeEducationDetail,
    // EmployeeSummaryDetail
  },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
};
</script>

<style scoped></style>
