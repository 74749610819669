<template>
  <EmployeeContactsDetailForm></EmployeeContactsDetailForm>
</template>

<script>
import EmployeeContactsDetailForm from "@/modules/company/components/employees/details/EmployeeContactsDetailForm";
export default {
  name: "EmployeeContactsDetail",
  components: { EmployeeContactsDetailForm },
};
</script>

<style scoped></style>
