<template>
  <b-modal
    id="employee-admin-assignment-modal"
    title-class="h4 font-weight-bold"
    :title="employee.name + ' as Admin'"
    size="sm"
    lazy
    @ok="assignAdmin"
    @close="onModalClose"
    ok-only
    :ok-disabled="isBusy"
  >
    <b-alert show="" variant="light"
      >Please enter your SmartId account password to assign this user as your
      <strong>Company Admin.</strong></b-alert
    >
    <b-form @submit.stop.prevent v-if="!isBusy">
      <b-form-group label="Password">
        <b-form-input type="password" v-model="form.password"></b-form-input>
      </b-form-group>
    </b-form>
    <AppLoader v-if="isBusy"></AppLoader>
  </b-modal>
</template>

<script>
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "CompanyAdminAssignmentModal",
  components: { AppLoader },
  props: ["employee"],
  data() {
    return {
      form: {
        password: "",
      },
      isBusy: false,
    };
  },
  methods: {
    assignAdmin(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.isBusy = true;
      this.$store
        .dispatch("setEmployeeAsAdmin", {
          employee_id: this.employee.id,
          password: this.form.password,
        })
        .then((response) => {
          console.log("Assigned as admin!", response);
          let message = "Assigned " + this.employee.name + " as Company Admin";
          this.$bvToast.toast(message, {
            title: "Success",
            variant: "success",
          });
          // Hide the modal manually
          this.$nextTick(() => {
            this.form.password = "";
            this.$bvModal.hide("employee-admin-assignment-modal");
          });
        })
        .finally(() => (this.isBusy = false));
    },
    onModalClose() {
      this.form.password = "";
    },
  },
};
</script>

<style scoped></style>
