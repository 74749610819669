<template>
  <b-form class="form" v-on:submit.prevent="onSubmit">
    <h4 class="font-weight-bolder mb-5"></h4>

    <span class="d-flex mb-5 align-items-center">
      <!--        <span class="svg-icon svg-icon-primary svg-icon-3x mr-4">-->
      <!--          <inline-svg src="/media/svg/icons/Layout/Layout-horizontal.svg" />-->
      <!--        </span>-->
      <span class="h5 font-weight-bolder">{{
        $t("EMPLOYEES.DETAILS.CONTACT")
      }}</span>
    </span>

    <b-form-group
      id="employee_contact_number"
      label-cols-lg="2"
      label-for="employee_contact_number"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.CONTACT')"
    >
      <b-form-input
        id="employee_contact_number"
        :placeholder="$t('EMPLOYEEFORM.EMERGENCYNUMBER')"
        v-model="$v.form.employee_contact_number.$model"
        :state="validateState('employee_contact_number')"
      >
      </b-form-input>

      <b-form-invalid-feedback
        v-if="!$v.form.employee_contact_number.required"
        >{{ $t("ALERT.ALERT") }}</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      id="emergency_name"
      label-cols-lg="2"
      label-for="emergency_name"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.EMERGENCY')"
    >
      <b-form-input
        id="emergency_name"
        :placeholder="$t('EMPLOYEEFORM.EMERGENCYCONTACT')"
        v-model="$v.form.emergency_name.$model"
        :state="validateState('emergency_name')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.emergency_name.required"
        >{{ $t("ALERT.ALERT") }}!</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      id="relationships"
      label-cols-lg="2"
      label-for="relationships"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.RELATIONSHIP')"
    >
      <b-form-select
        id="relationships"
        v-model="$v.form.relation_id.$model"
        :options="relationships"
        :state="validateState('relation_id')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.relation_id.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <span class="d-flex mb-5 align-items-center">
      <!--        <span class="svg-icon svg-icon-primary svg-icon-3x mr-4">-->
      <!--          <inline-svg src="/media/svg/icons/Layout/Layout-horizontal.svg" />-->
      <!--        </span>-->
      <span class="h5 font-weight-bolder">{{
        $t("EMPLOYEES.DETAILS.ADDRESS")
      }}</span>
    </span>

    <b-form-group
      id="add_1"
      label-cols-lg="2"
      label-for="add_1"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.ADDRESS1')"
    >
      <b-form-input
        id="add_1"
        v-model="$v.form.add_1.$model"
        :state="validateState('add_1')"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.add_1.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="add_2"
      label-cols-lg="2"
      label-for="add_2"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.ADDRESS2')"
    >
      <b-form-input
        id="input-8"
        v-model="form.add_2"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="add_3"
      label-cols-lg="2"
      label-for="input-8"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.ADDRESS3')"
    >
      <b-form-input
        id="input-8"
        v-model="form.add_3"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>
    </b-form-group>

    <!-- <b-form-group
      id="locality"
      label-cols="2"
      label-for="locality"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.LOCALITY')"
    >
      <b-form-input
        id="locality"
        v-model="$v.form.locality.$model"
        :state="validateState('locality')"
        :placeholder="$t('EMPLOYEEFORM.LOCALITY')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.locality.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group> -->

    <b-form-group
      id="city_town"
      label-cols-lg="2"
      label-for="city_town"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.CITY')"
    >
      <b-form-input
        id="city_town"
        v-model="$v.form.city_town.$model"
        :state="validateState('city_town')"
        :placeholder="$t('EMPLOYEEFORM.TOWN')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.city_town.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="postal_code"
      label-cols-lg="2"
      label-for="postal_code"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.POSTALCODE')"
    >
      <b-form-input
        id="postal_code"
        v-model="$v.form.postal_code.$model"
        :state="validateState('postal_code')"
        :placeholder="$t('EMPLOYEEFORM.POSTALCODE')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.postal_code.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="state"
      label-cols-lg="2"
      label-for="state"
      :label="$t('EMPLOYEESCONTACT.MESSAGE.STATE')"
    >
      <b-form-select
        id="state"
        v-model="$v.form.state.$model"
        :options="address_states"
        :state="validateState('state')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.state.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>
    <div class="d-flex justify-content-end border-top pt-3">
      <b-button type="reset" variant="light" class="mr-2 font-size-h6">
        {{ $t("EMPLOYEES.DETAILS.RESET") }}</b-button
      >

      <button
        ref="employee-contacts-button"
        class="btn btn-primary text-white font-weight-light font-size-h6"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { integer, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "EmployeeContactsDetailForm",
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        employee_contact_number: "",
        emergency_name: "",
        relation_id: "",
        add_1: "",
        add_2: "",
        add_3: "",
        locality: "",
        city_town: "",
        postal_code: "",
        state: "",
        id: null,
      },
      relationships: [],
      address_states: [],
    };
  },
  validations: {
    form: {
      employee_contact_number: {
        required,
      },
      emergency_name: {
        required,
      },
      relation_id: {
        required,
        integer,
      },
      add_1: {
        required,
      },
      add_2: {},
      locality: {},
      city_town: {
        required,
      },

      postal_code: {
        required,
        integer,
      },

      state: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Contact Details Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["employee-contacts-button"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("submitEmployeeContactDetails", this.form)
        .then(() => {
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchContactDetailForm").then((response) => {
      const formData = response.data.data;

      this.form = {
        id: formData.id,
        employee_contact_number: formData.contact_number,
        emergency_name: formData.emergency_contact.name,
        relation_id: 1,
        add_1: formData.emergency_contact.address.add_1,
        add_2: formData.emergency_contact.address.add_2,
        add_3: formData.emergency_contact.address.add_3,
        locality: formData.emergency_contact.address.locality,
        city_town: formData.emergency_contact.address.city_town,
        postal_code: formData.emergency_contact.address.postal_code,
        state: formData.emergency_contact.address.state.id,
      };
    });

    this.$store.dispatch("fetchContactRelations").then((response) => {
      console.log(response.data.relation);

      let relationships = [];
      response.data.relation.forEach((rel) => {
        relationships.push({
          value: rel.id,
          text: rel.name + " - " + rel.description,
        });
      });
      this.relationships = relationships;

      let states = [];
      response.data.states.forEach((rel) => {
        states.push({
          value: rel.id,
          text: rel.name + " - " + rel.description,
        });
      });
      this.address_states = states;
    });
  },
  computed: {
    ...mapState({
      relationList: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.relationships,
      stateList: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.states,
      employeeId: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.employeeId,
    }),
  },
};
</script>

<style scoped></style>
