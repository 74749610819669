<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold my-5">{{ title }}</h4>

    <!-- begin:Form   -->
    <b-form class="form" v-on:submit.prevent="save">
      <!--  begin:First Name   -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.FIRSTNAME')">
        <b-form-input
          id="employee-first-name-input"
          class="form-control h-auto"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          :placeholder="$t('EMPLOYEEFORM.NAME')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:First Name   -->

      <!--  begin:Last Name    -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.LASTNAME')">
        <b-form-input
          id="employee-email-input"
          class="form-control h-auto"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          :placeholder="$t('EMPLOYEEFORM.LASTNAME')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:Last Name    -->

      <!--  begin:Email    -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')">
        <b-form-input
          id="employee-email-input"
          class="form-control h-auto"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
          :placeholder="$t('EMPLOYEEFORM.EMAIL')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:Email    -->

      <!-- begin:Unique Code -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.UNIQUE')">
        <b-form-input
          id="employee-unique-code-input"
          class="form-control h-auto"
          v-model="$v.form.uniqueCode.$model"
          :state="validateState('uniqueCode')"
          :placeholder="$t('EMPLOYEEFORM.UNIQUECODE')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:Unique    -->

      <!-- begin:Employment Date -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.EMPLOYEMENT')">
        <b-form-datepicker
          id="employee-employment-date-input"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
          class="form-control h-auto"
        >
        </b-form-datepicker>
      </b-form-group>
      <!--  end:Employment Date -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="submit"
          class="btn btn-primary font-weight-bolder"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
    <!-- end:Form   -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, email, required } from "vuelidate/lib/validators";

export default {
  name: "EmployeeForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Employee Form",
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        uniqueCode: "",
        date: "",
      },
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      uniqueCode: {
        required,
      },
      date: {},
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const submitButton = this.$refs["submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const firstName = this.form.firstName;
      const lastName = this.form.lastName;
      const email = this.form.email;
      const uniqueCode = this.form.uniqueCode;
      const date = this.form.date;

      this.$store
        .dispatch("saveEmployee", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          unique_code: uniqueCode,
          employment_date: date,
        })
        .then((response) => this.$emit("success", response.data))
        .catch((error) => this.$emit("error", error))
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
  },
};
</script>

<style scoped></style>
