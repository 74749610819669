<template>
  <b-form v-on:submit.prevent="onSubmit">
    <h4 class="font-weight-bolder mb-5"></h4>

    <span class="d-flex mb-5 align-items-center">
      <!--        <span class="svg-icon svg-icon-primary svg-icon-3x mr-4">-->
      <!--          <inline-svg src="/media/svg/icons/Layout/Layout-horizontal.svg" />-->
      <!--        </span>-->
      <span class="h5 font-weight-bolder">{{
        $t("EMPLOYEES.DETAILS.GENERAL")
      }}</span>
    </span>

    <b-form-group
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.DOB')"
      label-cols-lg="2"
      content-cols-lg="8"
    >
      <b-form-datepicker
        id="date_birth"
        :placeholder="$t('EMPLOYEESPERSONAL.MESSAGE.DOB1')"
        v-model="$v.form.date_birth.$model"
        :state="validateState('date_birth')"
      ></b-form-datepicker>
    </b-form-group>

    <b-form-group
      id="genders"
      label-for="genders"
      label-cols-lg="2"
      content-cols-lg="8"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.GENDER')"
    >
      <b-form-select
        id="genders"
        v-model="$v.form.gender.$model"
        :options="genders"
        :state="validateState('gender')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.gender.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="martial_id"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.MARITALID')"
      label-for="martial_id"
      label-cols-lg="2"
      content-cols-lg="8"
    >
      <b-form-select
        id="martial_id"
        v-model="$v.form.martial_id.$model"
        :options="marital_status"
        :state="validateState('martial_id')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.martial_id.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <span class="d-flex mb-5 align-items-center">
      <!--        <span class="svg-icon svg-icon-primary svg-icon-3x mr-4">-->
      <!--          <inline-svg src="/media/svg/icons/Layout/Layout-horizontal.svg" />-->
      <!--        </span>-->
      <span class="h5 font-weight-bolder">{{
        $t("EMPLOYEES.DETAILS.ADDRESS")
      }}</span>
    </span>

    <b-form-group
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.ADDRESS1')"
      id="add_1"
      label-for="add_1"
      label-cols-lg="2"
    >
      <b-form-input
        id="add_1"
        v-model="$v.form.add_1.$model"
        :state="validateState('add_1')"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.add_1.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="add_2"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.ADDRESS2')"
      label-for="add_2"
      label-cols-lg="2"
    >
      <b-form-input
        id="add_2"
        v-model="form.add_2"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="add_3"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.ADDRESS3')"
      label-for="add_3"
      label-cols-lg="2"
    >
      <b-form-input
        id="add_3"
        v-model="form.add_3"
        :placeholder="$t('EMPLOYEEFORM.ADD')"
      ></b-form-input>
    </b-form-group>

    <!--  <b-form-group
      id="locality"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.LOCALITY')"
      label-for="locality"
      label-cols-lg="2"
    >
      <b-form-input
        id="locality"
        v-model="$v.form.locality.$model"
        :state="validateState('locality')"
        :placeholder="$t('EMPLOYEEFORM.LOCALITY')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.locality.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>-->

    <b-form-group
      id="city_town"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.CITY')"
      label-for="city_town"
      label-cols-lg="2"
    >
      <b-form-input
        id="city_town"
        v-model="$v.form.city_town.$model"
        :state="validateState('city_town')"
        :placeholder="$t('EMPLOYEEFORM.TOWN')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.locality.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="postal_code"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.POSTALCODE')"
      label-for="postal_code"
      label-cols-lg="2"
    >
      <b-form-input
        id="postal_code"
        v-model="$v.form.postal_code.$model"
        :state="validateState('postal_code')"
        :placeholder="$t('EMPLOYEEFORM.POSTALCODE')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.postal_code.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="state"
      :label="$t('EMPLOYEESPERSONAL.MESSAGE.STATE')"
      label-for="state"
      label-cols-lg="2"
    >
      <b-form-select
        id="state"
        v-model="$v.form.state.$model"
        :options="address_states"
        :state="validateState('state')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.state.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>
    <div class="d-flex justify-content-end border-top pt-3">
      <b-button type="reset" variant="light" class="mr-2 font-size-h6">{{
        $t("EMPLOYEES.DETAILS.RESET")
      }}</b-button>

      <button
        ref="employee-personal-button"
        class="btn btn-primary text-white font-weight-light font-size-h6"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "EmployeePersonalDetailForm",
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        date_birth: "",
        martial_id: "",
        gender: "",
        add_1: "",
        add_2: "",
        add_3: "",
        locality: "",
        city_town: "",
        postal_code: "",
        state: "",
        id: null,
      },
      genders: [
        { value: "M", text: "Male" },
        { value: "F", text: "Female" },
      ],
      marital_status: [],
      address_states: [],
    };
  },
  validations: {
    form: {
      date_birth: {
        required,
      },
      gender: {
        required,
      },
      martial_id: {
        required,
      },
      add_1: {
        required,
      },
      add_2: {},
      locality: {},

      city_town: {
        required,
      },

      postal_code: {
        required,
      },

      state: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["employee-personal-button"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("submitEmployeePersonalDetails", this.form)
        .then((data) => {
          this.form.id = data.id;
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchPersonalDetailForm").then((response) => {
      const formData = response.data;
      console.log(response.data);

      this.form = {
        id: formData.id,
        date_birth: formData.date_of_birth,
        gender: formData.gender.trim(),
        martial_id: formData.martial_id,
        add_1: formData.address.add_1,
        add_2: formData.address.add_2,
        add_3: formData.address.add_3,
        locality: formData.address.locality,
        city_town: formData.address.city_town,
        postal_code: formData.address.postal_code,
        state: formData.address.state.id,
      };
    });

    this.$store.dispatch("fetchContactRelations").then((response) => {
      console.log(response.data.relation);

      let states = [];
      response.data.states.forEach((rel) => {
        states.push({
          value: rel.id,
          text: rel.name + " - " + rel.description,
        });
      });
      this.address_states = states;
    });

    this.$store.dispatch("fetchMartialStatus").then((response) => {
      console.log(response.data.relation);

      let martials = [];
      response.data.forEach((martial) => {
        martials.push({
          value: martial.id,
          text: martial.name,
        });
      });
      this.marital_status = martials;
    });
  },
  computed: {
    ...mapState({
      relationList: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.relationships,
      stateList: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.states,
      employeeId: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.employeeId,
    }),
  },
};
</script>

<style scoped></style>
